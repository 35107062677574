<template>
	<div class="aboutus">
		<site-header></site-header>
		<header-search></header-search>
		<bread-line :showLevel="1"></bread-line>
		<div class="about">
			<div class="aboutmenu">
				<div v-for="item in list" :key="item.name" @click="tourl(item.url)"
					:class="{ menuitem: true, selectitem: item.isSelect }">{{ item.name
					}}
				</div>

			</div>
			<div class="aboutcontent">
				<router-view/>
			</div>
		</div>

		<!-- showLevel   //0 最后一级   1 第一级  2 两级 -->
		<!-- <nav-menu></nav-menu> -->
		<div style="width:100%;height:70px;background:rgba(240, 243, 248, 1)"></div>

		<index-footer></index-footer>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import HeaderSearch from '@/components/website/HeaderSearch.vue'

import IndexFooter from '@/components/website/IndexFooter.vue'
import BreadLine from '@/components/website/BreadLine.vue'
import NavMenu from '@/components/website/NavMenu.vue'
export default {
	name: 'PcWebsiteIndex',
	components: { SiteHeader, HeaderSearch, NavMenu, IndexFooter, BreadLine },

	data() {
		return {
			list: [
				{
					name: '土拍简介',
					isSelect: false,
					path: 'about/intro'
				},
				{
					name: '土拍资源',
					isSelect: false,
					path: 'about/resource'
				},
				{
					name: '土拍优势',
					isSelect: false,
					path: 'about/advantage'
				}, {

					name: '专业服务',
					isSelect: false,
					path: 'about/service'
				}
			]
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {
		this.initlist()
	},

	watch:{
		$route:{
			handler(v){
				this.initlist()
			}
		}
	},

	methods: {
		initlist() {
			const name = this.$route.meta.title
			const path = this.site.path;
			const list = this.list.map(item => {
				if (name === item.name) {
					item.isSelect = true
				} else {
					item.isSelect = false
				}

				item.url = '/' + path + '/' + item.path
				return item
			})

			this.list = list
		},
		tourl(path) {
			this.$router.push(path)
		}

	},
};
</script>

<style lang="scss" scoped>
.aboutus {
	width: 100%;
	height: auto;
	background: #F0F3F8;



}

.about {
	width: 1200px;
	margin: 0 auto;
	display: flex;

	.aboutmenu {
		.menuitem {
			width: 260px;
			margin-bottom: 4px;
			margin-right:4px;
			height: 52px;
			background: #FFFFFF;
			//
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;

			line-height: 52px;
			color: #000000;
			text-align: left;
			padding-left: 18px;
			box-sizing: border-box;
			cursor: pointer;
		}

		.selectitem {
			background: #28A7E1;
			color: #FFFFFF;
			text-align: left;
		}

	}

	.aboucontent {}

}
.aboutcontent{
	// flex:1;
	width:936px;
	background:#fff;
	padding:10px;
	box-sizing: border-box;
}
</style>